import { useEffect, useState } from "react";

import "./App.css";
import WelcomeScreen from "./screens/WelcomeScreen/WelcomeScreen";
import RegistrationScreen from "./screens/RegistrationScreen/RegistrationScreen";
import PinCodeScreen from "./screens/PinCodeScreen/PinCodeScreen";

import Authorized from "./screens/Authorized/Autorized";
import { api } from "./api/api";
import LoadingScreen from "./screens/Loading/LoadingScreen";
import { retrieveLaunchParams } from "@telegram-apps/sdk-react";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_LANGUAGE,
  LANGUAGE_STORAGE_KEY,
  LanguageKeys,
  languages,
} from "./constants";
import { setCookie } from "./helpers/cockieHelper";
import { postEvent } from "@telegram-apps/sdk";
import { init } from "@telegram-apps/sdk";

init();

export interface UserData {
  id: string;
  name: string;
}

function App() {
  const [screen, setScreen] = useState("loading");
  const [startParam, setStartParam] = useState("");
  const { i18n } = useTranslation();

  useEffect(() => {
    postEvent("web_app_request_fullscreen");
    postEvent("web_app_expand");
  }, []);

  useEffect(() => {
    const choosedLanguage = localStorage.getItem(LANGUAGE_STORAGE_KEY) as
      | LanguageKeys
      | undefined;
    if (choosedLanguage && languages.includes(choosedLanguage)) {
      i18n.changeLanguage(choosedLanguage);
    } else {
      i18n.changeLanguage(DEFAULT_LANGUAGE);
    }
  }, []);

  const { initDataRaw, initData } = retrieveLaunchParams();

  // const initDataRow =
  //   "query_id=AAGQVd8jAwAAAJBV3yNPcnUA&user=%7B%22id%22%3A7044289936%2C%22first_name%22%3A%22%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B9%22%2C%22last_name%22%3A%22%D0%93%D0%BE%D1%80%D0%B4%D0%B5%D0%B5%D0%B2%22%2C%22username%22%3A%22DGordeew%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2FTVsOci5gAhREgPi_92_ADuZbDUiJfeTfdofgYoJPBKz1ABfncMSxQehqhPxVhQ_K.svg%22%7D&auth_date=1736848238&signature=Fiw41zN7LRfgLZrkTOepG2FRa_nWr3O4oikhi4L22QmLWKBTKyp0udqSVqfzM0hjF9Y33Sn2x4Rvp8EdT-uGBw&hash=bde857fc67577bcca6daa908d432facae8ccc7885ff1f1c64f2105cf508228be";

  const telegramAuth = async () => {
    if (!initDataRaw) return;
    try {
      const body = initData?.startParam
        ? {
            init_data: initDataRaw,
            invite_code: initData?.startParam,
          }
        : {
            init_data: initDataRaw,
          };

      // const body = {
      //   init_data: initDataRow,
      // };

      const response = await api.post("accounts/telegram_auth/", body, {});
      const isUserPassword = response.data.password;
      setCookie("temp_token", response.data.temp_token || "no_token", 7);

      if (isUserPassword) {
        setScreen("login");
      } else {
        setScreen("pin-code");
      }
      return response.data;
    } catch (error) {
      return false;
    }
  };

  const [userData, setUserData] = useState<UserData>({
    id: initData?.user?.id.toString() || "",
    name: initData?.user?.firstName || "",
  });

  // const [userData, setUserData] = useState<UserData>({
  //   id: "7044289936",
  //   name: "egor",
  // });

  useEffect(() => {
    telegramAuth();
  }, []);

  const appRender = () => {
    switch (screen) {
      case "welcome":
        return <WelcomeScreen setScreen={setScreen} />;
      case "registration":
        return (
          <RegistrationScreen
            setScreen={setScreen}
            userData={userData}
            startParam={startParam}
          />
        );
      case "login":
        return (
          <LoginScreen
            setScreen={() => setScreen("authorized")}
            userData={userData}
          />
        );
      case "pin-code":
        return (
          <PinCodeScreen
            setScreen={() => setScreen("login")}
            userId={userData.id}
          />
        );
      case "authorized":
        return <Authorized />;
      case "loading":
        return <LoadingScreen />;
      default:
        return <LoadingScreen />;
    }
  };
  return appRender();
}

export default App;
